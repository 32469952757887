// i18next-extract-mark-ns-start eurm
import {SEO} from 'components/SEO';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import heroImg from 'images/affiliates_hero.png';
import check from 'images/check.svg';

//Clients
//Images
import dots from 'images/dots.svg';

//Payment methods
import mobilePaymentSuccess from 'images/mobile_payment_success.png';
import tapfiliate from 'images/tapfiliate.png';

import React, {useState} from 'react';
import styled from 'styled-components';
import {containerSize} from '../../types/responsive';
import {EurmButton} from 'components/EurmButton';

// Webkit cannot style progress so we fake it with a long shadow on the thumb element
const makeLongShadow = (color: String, size: string) => {
  let i = 4;
  let shadow = `${i}px 0 0 ${size} ${color}`;

  for (; i < 706; i++) {
    shadow = `${shadow}, ${i}px 0 0 ${size} ${color}`;
  }

  return shadow;
};

const Index: React.FC = () => {
  const [slider, setSlider] = useState({value: 2500, earnings: 40});
  const {t} = useI18next();
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: number = parseInt(e.target.value);
    setSlider({value, earnings: Math.round(value * 1.6) / 100});
  };

  return (
    <>
      <SEO
        path="eurm"
        title={t('EURM the first EU Regulated Stablecoin')}
        description={t(
          'Be part of the future of payments with EURM, the first EU Regulated Stablecoin.'
        )}
      />
      <Container>
        <Content>
          <Hero>
            <HeroWrapper>
              <Left>
                <HeroTitle>
                  <Trans>EURM the first EU Regulated Stablecoin</Trans>
                </HeroTitle>
                <HeroDescription>
                  <Trans>Join now to become an early EURM tester</Trans>
                </HeroDescription>
                <EurmButton variant="light">
                  <Trans>Join EURM Now</Trans>
                </EurmButton>
              </Left>
              <Right>
                <HeroImg
                  src={heroImg}
                  alt="EURM Stablecoin"
                  title="EURM by MONEI Stablecoin"
                  width={694}
                />
              </Right>
            </HeroWrapper>
          </Hero>
          <InfoSection>
            <InfoWrapper>
              <InfoLeft>
                <QrScan
                  src={mobilePaymentSuccess}
                  alt="MONEI Wallet"
                  title="MONEI Wallet"
                  width={669}
                />
              </InfoLeft>
              <InfoRight>
                <SectionTitle>
                  <Trans>Send EUR worldwide + real time + free!</Trans>
                </SectionTitle>
                <SectionDescription>
                  <Trans>
                    EURM allows any business or individual to send EUR for free, real time &
                    worldwide.
                  </Trans>
                </SectionDescription>
                <BulletSection>
                  <Circle>
                    <Icon src={check} width={12} />
                  </Circle>
                  <BulletText>
                    <Trans>Fast & Easy Signup</Trans>
                  </BulletText>
                </BulletSection>
                <BulletSection>
                  <Circle>
                    <Icon src={check} width={12} />
                  </Circle>
                  <BulletText>
                    <Trans>EU Regulated</Trans>
                  </BulletText>
                </BulletSection>
                <BulletSection>
                  <Circle>
                    <Icon src={check} width={12} />
                  </Circle>
                  <BulletText>
                    <Trans>Fully 2x Backed with real EUR + Spanish Treasury Green Bonds</Trans>
                  </BulletText>
                </BulletSection>
                <BulletSection>
                  <Circle>
                    <Icon src={check} width={12} />
                  </Circle>
                  <BulletText>
                    <Trans>Available within the most popular exchanges</Trans>
                  </BulletText>
                </BulletSection>
              </InfoRight>
            </InfoWrapper>
          </InfoSection>

          <Calculator>
            <CalculatorWrapper>
              <Left>
                <SectionTitle>
                  <Trans>How much can I save?</Trans>
                </SectionTitle>
                <SectionDescription>
                  <Trans>
                    With EURM by MONEI
                    <Bold> you pay 0 EUR </Bold>
                    to send money to your friends, family or any business.
                  </Trans>
                </SectionDescription>
                <SectionDescription>
                  <Trans>
                    <Bold>Example Scenario</Bold> You send 2.500 EURM to a friend who lives in
                    Mexico. He would get 59.000 MXN in less than 1 minute, and you would save 40 EUR
                    compared to banks.
                  </Trans>
                </SectionDescription>
              </Left>
              <Right>
                <CalculatorContainer>
                  <CalculatorTitle>
                    <Trans>How much will you send?</Trans>
                  </CalculatorTitle>
                  <CalculatorNumber>{slider.value} €</CalculatorNumber>
                  <RangeSection>
                    <InputRange
                      type="range"
                      min={1}
                      max={10000}
                      value={slider.value}
                      onChange={handleOnChange}
                    />
                    <SpaceBetweenRow>
                      <RangeNumber>0</RangeNumber>
                      <RangeNumber>10.000 €</RangeNumber>
                    </SpaceBetweenRow>
                  </RangeSection>
                  <EurmButton variant="dark">
                    <Trans>Join now and save</Trans> {slider.earnings} €
                  </EurmButton>
                </CalculatorContainer>
                <SquareBackdrop />
                <Dots src={dots} width={120} />
              </Right>
            </CalculatorWrapper>
          </Calculator>

          <Cta>
            <CtaWrapper>
              <CtaContent>
                <SectionTitle style={{color: 'white'}}>
                  <Trans>Help us grow the future of payments</Trans>
                </SectionTitle>
                <SectionDescription style={{color: '#DFE6F6'}}>
                  <Trans>Join the payments revolution</Trans>
                </SectionDescription>
                <EurmButton variant="darkLight">
                  <Trans>I want to join EURM</Trans>
                </EurmButton>
              </CtaContent>
              <Tapfiliate src={tapfiliate} width={400} />
            </CtaWrapper>
          </Cta>
        </Content>
      </Container>
    </>
  );
};

export default Index;

const Container = styled.div`
  width: 100%;
  position: relative;
  background-color: white;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${containerSize};
  margin: 0 auto;
  padding: 0 20px;
  background-color: white;
`;

//HERO SECTION

const Hero = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const HeroWrapper = styled.div`
  display: grid;
  grid-column-gap: 5%;
  grid-template-columns: 45% 50%;
  @media (max-width: 768px) {
    grid-template-columns: 100%;
    justify-items: center;
  }
`;

const Left = styled.div`
  position: relative;
`;

const HeroTitle = styled.h1`
  font-weight: 600;
  color: #000000;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const HeroDescription = styled.p`
  font-weight: 500;
  color: #707070;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Right = styled.div`
  position: relative;
`;

const HeroImg = styled.img``;

const InfoSection = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-column-gap: 5%;
  grid-template-columns: 50% 40%;
  align-items: start;
  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

const InfoLeft = styled.div`
  position: relative;
  @media (max-width: 768px) {
    order: 2;
  }
`;

const InfoRight = styled.div`
  position: relative;

  @media (max-width: 768px) {
    order: 1;
    padding-bottom: 40px;
  }
`;

const QrScan = styled.img`
  position: relative;
  z-index: 1;
`;

const SectionTitle = styled.h2`
  font-weight: 600;
  color: #000000;
  font-size: 26px;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const SectionDescription = styled.p`
  font-weight: 500;
  color: #707070;
  font-size: 16px;
`;

const BulletSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 24px;
  background-color: #5e80d2;
`;

const BulletText = styled.p`
  margin-bottom: 0px;
  font-weight: 500;
  color: black;
  padding-left: 16px;
  line-height: 130%;
`;

const Icon = styled.img`
  margin-bottom: 0px;
`;

const Calculator = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const CalculatorWrapper = styled.div`
  display: grid;
  grid-column-gap: 5%;
  grid-template-columns: 45% 50%;
  align-items: center;
  justify-items: self-end;
  @media (max-width: 768px) {
    grid-template-columns: 100%;
    justify-items: center;
  }
`;

const Bold = styled.span`
  display: inline;
  font-weight: 600;
  color: #5e80d2;
`;

const CalculatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 449px;
  padding: 40px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  box-shadow: 8px 12px 32px rgba(0, 0, 0, 0.06);
  position: relative;
  z-index: 1;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CalculatorTitle = styled.p`
  font-weight: 500;
  color: black;
  font-size: 18px;
`;

const CalculatorNumber = styled.p`
  font-weight: 500;
  color: black;
  font-size: 42px;
`;

const RangeSection = styled.div`
  width: 100%;
`;

const SpaceBetweenRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const RangeNumber = styled.p`
  font-weight: 500;
  color: black;
`;

const SquareBackdrop = styled.div`
  position: absolute;
  width: 320px;
  height: 254px;
  background-color: #f5f8ff;
  top: -60px;
  right: -60px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Dots = styled.img`
  position: absolute;
  bottom: -80px;
  right: -50px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const InputRange = styled.input`
  overflow: hidden;
  display: block;
  appearance: none;
  max-width: 700px;
  width: 100%;
  margin: 0;
  height: 36px;
  cursor: pointer;
  border-radius: 40px;

  &::-webkit-slider-runnable-track {
    width: 100%;
    border-radius: 40px;
    height: 8px;
    border-radius: 40px;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: #5e80d2;
    border-radius: 40px;
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: 24px;
    width: 24px;
    background: #425992;
    border-radius: 100%;
    border: 0;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: ${makeLongShadow('#E7ECF8', '-8px')};
    transition: background-color 150ms;
  }

  &::-moz-range-track,
  &::-moz-range-progress {
    width: 100%;
    height: 8px;
    background: blue;
  }

  &::-moz-range-progress {
    background: blue;
  }

  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: 32px;
    width: 32px;
    background: blue;
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
  }

  &::-ms-track {
    width: 100%;
    height: 8px;
    border: 0;
    /* color needed to hide track marks */
    color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    background: blue;
  }

  &::-ms-fill-upper {
    background: blue;
  }

  &::-ms-thumb {
    appearance: none;
    height: 32px;
    width: 32px;
    background: blue;
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    /* IE Edge thinks it can support -webkit prefixes */
    top: 0;
    margin: 0;
    box-shadow: none;
  }
`;

const Cta = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const CtaWrapper = styled.div`
  background-color: #5e80d2;
  border-radius: 16px;
  padding: 40px 80px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

const CtaContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
  }
`;

const Tapfiliate = styled.img`
  position: absolute;
  right: -10px;
  bottom: -10px;
  margin-bottom: 0px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "eurm"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
